import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import HeaderNoHero from "../components/HeaderNoHero"
import SEO from "../components/seo"
import { withPreview } from "gatsby-source-prismic"

const TermsOfUse = ({ data }) => {
  const section = data.prismicPrivacyPolicyPage.data

  return (
    <Layout>
      <SEO title="Terms of Use" />
      <HeaderNoHero title={section.hero_title.text} subtitle="" />
      <div className="uk-section">
        <div className="uk-container">
          <div dangerouslySetInnerHTML={{ __html: section.page_body.html }} />
        </div>
      </div>
    </Layout>
  )
}

export default withPreview(TermsOfUse)

export const termsOfUseQuery = graphql`
  {
    prismicPrivacyPolicyPage(uid: { eq: "terms-use" }) {
      uid
      data {
        hero_title {
          html
          text
        }
        page_body {
          html
        }
        page_title
      }
    }
  }
`